function toBase64(str) {
    const bytes = new TextEncoder().encode(str);
    return btoa(Array.from(bytes, (byte) => String.fromCodePoint(byte)).join(""));
}

export default function HiddenMail({ id, children, user = "hei", at = "[at]", domain = "ascendi[dot]no" }) {
    const hash = toBase64(children);
    return (
        <a id={id} className="font-medium" data-mail={hash}>{user}[at]{domain}</a>
    )
}