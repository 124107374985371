"use client"

import React, { useEffect, useState } from "react";
import { AscendiLogo } from "./logo";
import Link from "next/link";
import nav from "./nav.module.css";

// TODO: Include ARIA
const navbar = {
	'height': '4rem',
	'zone': '0.45rem',
	'menuBtnZone': '4rem',
}

export default function Navbar() {
	// const [nav, loadStylesheet] = useState({});
	const [isMenuOpen, setMenuState] = useState(false);

	const menuItems = [
		{ name: "Hjem", href: "/" },
		{ name: "Tjenester", href: "/#tjenester" },
		{ name: "Om Oss", href: "/#om" },
		{ name: "Blogg", href: "/blog/" },
		{ name: "Kontakt Oss", href: "/kontakt/", color: "warning" },
	];

	useEffect(() => {
		// Load stylesheet
		// import('./nav.module.css').then(loadStylesheet);

		// Untoggle mobile menu if screen size becomes too wide
		const handleResize = () => {
			if (window.innerWidth >= 640)
				setMenuState(false);
		};
	
		window.addEventListener('resize', handleResize);
	
		// Remove the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
		<input id={'nav-menustate'} style={{ display:'none' }} type="checkbox" checked={isMenuOpen} onChange={() => setMenuState(!isMenuOpen)}/>
		<nav className={nav.bar} 
			style={{ zIndex: 100, height:'100%', width: '100%', margin: 0, padding: 0, position: 'absolute', pointerEvents:'none', top:0 }}
			role="navigation"
		>
			<div style={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center', margin:'0 auto', padding:'0 1.5rem', gap: '1rem', height: navbar.height, maxWidth: '1024px', pointerEvents: 'all' }}>
				<Link href="/" className={`${nav.btn}`} style={{ flexGrow: '1', display: 'flex', alignItems: 'center' }} onClick={() => setMenuState(false)}>
					<AscendiLogo />
				</Link>
				{/* Desktop Menu */}
				<ul media={"screen and (min-width: 640px)"} className={`${nav.desktop}`} style={{ flexGrow:'0', display:'none', gap: '1rem'}}>
					{menuItems.map((item, i) => (
						<li key={i} className={nav.btn} style={{ height:navbar.height, lineHeight: navbar.height }}>
							<Link href={item.href} style={{ padding:`${navbar.zone} 0` }} tabIndex="0" role="link">
								{item.name}
							</Link>
						</li>
					))}
				</ul>
				{/* Mobile Menu Toggle */}
				<div className={nav.toggleMenu} media={"screen and (max-width: 640px)"}>
					<button
						aria-expanded={isMenuOpen}
						aria-label={isMenuOpen ? "Lukk navigeringsmeny" : "Åpne navigeringsmeny"}
						onClick={() => setMenuState(!isMenuOpen)}
						className={`${nav.menuBtn} ${isMenuOpen ? nav.opened : ''}`}
						style={{ display:'flex', height:navbar.height, width:navbar.height }}
					>
						<svg width="34" height="34" viewBox="0 0 100 100" style={{ fill: 'none', stroke: 'currentColor', strokeDasharray: '60 207', strokeWidth: '6', height: '100%' }}>
							<path className={nav.line} d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
							<path className={nav.line} d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
						</svg>
					</button>
				</div>
				{/* Mobile Menu */}
				<ul className={nav.dropdown} media={"screen and (max-width: 640px)"} style={{ display:'none' }}>
					{menuItems.map((item, i) => (
						<li key={i} className={nav.item} style={{
							transitionDelay: (((isMenuOpen ? (i + 6) * 25 : (menuItems.length - i) * 15))) + "ms"}}
						>
							<Link
								href={item.href}
								className={nav.btn}
								onClick={() => setMenuState(false)}
								>
								{item.name}
							</Link>
						</li>
					))}
					<li className={nav.spacer}/>
					<li className={`${nav.item} ${nav.contactInfo}`}>
						<a className={nav.contactCall} href="tel:+4798082082">Ta kontakt: <span style={{ fontWeight: 600 }}>98 082 082</span></a>
						<a className={nav.contactCall} href="tel:+4722330100">Døgnåpen vakt telefon: <span style={{ fontWeight: 600 }}>223 30 100</span></a>
					</li>
				</ul>
			</div>
		</nav>
		</>
	);
}
